import { useProgress } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

export let canyon: GLTF;
export let dino: GLTF;
export let littleDino: GLTF;
export let green: GLTF;
export let greenWithoutArmature: GLTF;

interface IModelsLoader {
}

const TotalCount = 74;

export default function ModelsLoadingIndicator(props: IModelsLoader) {
    const { loaded } = useProgress()

    if (loaded > TotalCount)
        console.log("Loading data exceeded total count. Update total count. Current value: " + loaded)

    return (
        <div className="load-progress-container">
            <img style={{ width: '150px', marginBottom: '1rem' }} src="/logo512.png" alt="logo" />
            
            <div style={{ display: 'flex', alignItems: 'center', width: '150px' }}>
                <div className="spinner-container">
                    <div className="loading-spinner">
                    </div>
                </div>
                <div className="load-progress-inner">
                    <div className="load-progress-bar" style={{ transform: `scaleX(${Math.min(loaded / TotalCount, 1)})` }}></div>
                    <span className="load-progress-data">{Math.min(Math.round(loaded / TotalCount * 100), 100)}%</span>
                </div>
            </div>
        </div>
    );
}