import { useLoader, useFrame } from '@react-three/fiber';
import {
    GLTF
} from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';
import { useEffect, useState } from 'react';
import { GetDistanceBetweenTwoObjects } from './utils/utils';
import { PalmTransforms } from './consts/PalmTransforms';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js'
import { Grab } from './Grab';
import { AnimationNames, ChainedAnimation, DinoAnimations, IPlayAnimationParams, LittleDinoAnimations } from './definitions/Animations';
import { useGLTF } from '@react-three/drei';
import Curve from './components/Curve';

interface IGLTFModelProps {
    onInit?: Function,
    listener: THREE.AudioListener,
    dropLittleDinoSpot?: THREE.Vector3,
    littleDinoInitPosition?: THREE.Vector3,
    onGrabLittleDino?: Function,
    onDropLittleDino?: Function,
}

type CurvePathAnimation = {
    object: THREE.Object3D,
    curve: THREE.CatmullRomCurve3,
    duration: number,
    startTime?: number,
    onFinish?: Function,
    treesShakingDistance?: number,
    delay?: number,
    started?: boolean,
    finished?: boolean,
    stepsCount: number,
    stepsAnimation: AnimationNames,
    stepsAnimationDuration: number,
    ignoreYChangesForRotation?: boolean,
    slerpRotation?: number,
}

const Config = {
    DinoWalkAnimationDuration: 2000,
    TreesShakingDistanceWhenDinoMovesNear: 4.2,

    PalmRustleAnimationDurationMin: 3000,
    PalmRustleAnimationDurationMax: 6000,
    PalmRustleAnimationDelayMultiplier: 70,
    PalmRustleLoopDurationMin: 15000,
    PalmRustleLoopDurationMax: 20000,
    PalmRustleAnimationWeight: 0.4,

    ShakePalmAnimationDuration: 6000,
    ShakePalmAnimationWeight: 0.3,
    ShakeFernAnimationDuration: 3000,
    ShakeGreenAnimationDuration: 4000,
    ShakeGreenAnimationWeight: 0.5,

    LittleDinoMeshName: "LittleDinoCollider",
    LittleDinoStepAnimationDuration: 800,

    SoundDistance: 10,
    SoundRolloffFactor: 1,
    

    ShowDebuggingInfo: false, // Do not affect build version even if set true
}

let dinoCurvePathAnimation: CurvePathAnimation | null = null;
let littleDinoCurvePathAnimation: CurvePathAnimation | null = null;

export let palmRustleActions: THREE.AnimationAction[] = []
let nextPalmRustleAnimationStartTime: number = 0;

export let mixers: THREE.AnimationMixer[] = [];
let actions: THREE.AnimationAction[] = []

export let canyon: GLTF;
export let dino: GLTF;
export let littleDino: GLTF;
export let green: GLTF;
export let greenWithoutArmature: GLTF;
export let greensScene: THREE.Group = new THREE.Group();

let jungleSound: THREE.PositionalAudio;
let roarSound: THREE.PositionalAudio;
let walkSound: THREE.PositionalAudio;
let littleDinoSound: THREE.PositionalAudio;

let dinoActionsChain: ChainedAnimation | undefined;
let littleDinoActionsChain: ChainedAnimation | undefined;

function LoadModels() {
    canyon = useGLTF("/models/canyon/Canyon.gltf", true);
    dino = useGLTF("/models/dinosaur/Dinosaur.gltf", true);
    littleDino = useGLTF("/models/littleDino/LittleDino.gltf", true);
    green = useGLTF("/models/green/Green.gltf", true);
    greenWithoutArmature = useGLTF("/models/greenWithoutArmature/GreenWithoutArmature.gltf", true);
}

function LoadSounds(listener: THREE.AudioListener) {
    [jungleSound] = useState(() => CreateSound(listener, "/assets/jungle.mp3", canyon.scene));
    [roarSound] = useState(() => CreateSound(listener, "/assets/roar.wav", dino.scene));
    [walkSound] = useState(() => CreateSound(listener, "/assets/walk.wav", dino.scene));
    [littleDinoSound] = useState(() => CreateSound(listener, "/assets/little-dino.mp3", littleDino.scene));
}

function CreateSound(listener: THREE.AudioListener, path: string, target: THREE.Object3D) : THREE.PositionalAudio {
    const sound = new THREE.PositionalAudio(listener);
    const soundBuffer = useLoader(THREE.AudioLoader, path);
    sound.setBuffer(soundBuffer);
    sound.setRefDistance(Config.SoundDistance);
    sound.setRolloffFactor(Config.SoundRolloffFactor);
    target.add(sound);
    return sound;
}

function SetupScene(model: GLTF, side: THREE.Side = THREE.FrontSide, frustumCulled?: boolean) {
    model.scene.traverse((child: any) => {
        if (child.isMesh || child.type === "SkinnedMesh") {
            if (frustumCulled !== undefined)
                child.frustumCulled = frustumCulled;

            child.material.side = side;

            if (child.isMesh && (child as THREE.Mesh).name.endsWith("Collider")) {
                child.material = new THREE.MeshBasicMaterial({transparent: true, opacity: 0});
                child.material.side = THREE.DoubleSide;
            }
        }
    })
}

function InitScenes() {
    for (let mixer of mixers) {
        mixer.stopAllAction();
        if (mixer.hasEventListener("finished", AnimationFinishedEventHandler))
            mixer.removeEventListener("finished", AnimationFinishedEventHandler);
    }

    actions = [];
    mixers = [];
    dinoActionsChain = undefined;
    littleDinoActionsChain = undefined;
    dinoCurvePathAnimation = null;
    littleDinoCurvePathAnimation = null;
    ExtractActionsFromModels([canyon, dino, littleDino]);
    ExtractActionsForGreen();

    jungleSound.stop();
    roarSound.stop();
    walkSound.stop();
    littleDinoSound.stop();

    PlaySound(jungleSound, null, 1, 0, true);
    PlaySound(littleDinoSound, null, 0.5, 0, true);
    
    // Get palm rustle actions sorted by palm x position (from right to left)
    palmRustleActions = []
    for (let green of greensScene.children.sort((a, b) => b.position.x - a.position.x)) {
        const shakeActions = actions.filter(x => x.getClip().name.startsWith("PalmRustle")).filter(x => x.getRoot().id === green.id);
        if (!shakeActions) continue;
        const palmsWithArmature = GetGreenObjectsWithArmature(green).filter(x => x.name.startsWith("Palm")).sort((a, b) => b.position.x - a.position.x);
        for (let element of palmsWithArmature) {
                const shakeAction = shakeActions.find(x => !x.isRunning() && x.getClip().name.slice(-3) === element.name.slice(-3));
                if (shakeAction)
                    palmRustleActions.push(shakeAction);
        }
    }
}

const GLTFModel = (props: IGLTFModelProps) => {
    LoadModels();
    [greensScene] = useState(() => CreateGreen());
    LoadSounds(props.listener);

    useEffect(() => {
        return () => {
            for (let mixer of mixers) {
                if (mixer.hasEventListener("finished", AnimationFinishedEventHandler))
                    mixer.removeEventListener("finished", AnimationFinishedEventHandler);
            }
        }
    }, [])

    useEffect(() => {
        SetupScene(canyon, THREE.FrontSide, true);
        SetupScene(dino, THREE.FrontSide, false);
        SetupScene(littleDino, THREE.FrontSide, true);

        InitScenes();

        props.onInit?.();
    }, [props]);

    let lastTime = 0;

    useFrame((state, delta) => {
        const time = Date.now();

        if (lastTime > 0) {
            for (let mixer of mixers)
                mixer.update((time - lastTime) / 1000);

            updateCurvePathAnimation(time, dinoCurvePathAnimation);
            updateCurvePathAnimation(time, littleDinoCurvePathAnimation);

            playRustleAnimations();
        }

        lastTime = time;
    })

    return (
        <>
            <primitive object={canyon.scene}/>
            <primitive object={dino.scene}/>
            <Grab position={props.littleDinoInitPosition} targetName={Config.LittleDinoMeshName} dropSpot={props.dropLittleDinoSpot} onGrab={() => { LittleDinoGrabbed(); props.onGrabLittleDino?.(); }} onDrop={() => { LittleDinoDrop(); props.onDropLittleDino?.(); } }>
                <primitive object={littleDino.scene}/>
            </Grab>
            <primitive object={greensScene} />
            
            { process.env.NODE_ENV === "development" && Config.ShowDebuggingInfo ?
                <group position={props.littleDinoInitPosition}>
                    <Curve color={new THREE.Color(0x55ffff)} curve={afterLittleDinoReachGroundCurve} startPoint={new THREE.Vector3().subVectors(props.dropLittleDinoSpot ?? new THREE.Vector3(), props.littleDinoInitPosition ?? new THREE.Vector3())} />
                </group>
            : null}
        </>
    )
}

const afterLittleDinoReachGroundCurve = new THREE.CatmullRomCurve3([
    new THREE.Vector3( 0, 0, 9 ),
    new THREE.Vector3( 0, 0, 9.4 ),
    new THREE.Vector3( 0, -0.16, 9.7 ),
    new THREE.Vector3( 0, -0.16, 10.5 ),
    new THREE.Vector3( 1, -0.16, 25 ),
    new THREE.Vector3( 2.7, -0.16, 40 ),
    new THREE.Vector3( 6, -0.16, 50 ),
    new THREE.Vector3( 11, -0.16, 60 ),
    new THREE.Vector3( 16, -0.16, 72 ),
]);

export function LittleDinoDrop() {
    const littleDinoObj = littleDino.scene.parent;
    if (!littleDinoObj) return;

    const littleDinoAnimations = actions.filter(x => x.getRoot().id === littleDino.scene.id);
    for (let littleDinoAnimation of littleDinoAnimations)
        littleDinoAnimation.stop();

    const stepsCount = 45;
    const afterReachGroundCurvePathAnimation = {
        object: littleDinoObj,
        duration: stepsCount * Config.LittleDinoStepAnimationDuration,
        curve: new THREE.CatmullRomCurve3([
            new THREE.Vector3( littleDinoObj.position.x, 0, littleDinoObj.position.z + 0.1 ),
            ...afterLittleDinoReachGroundCurve.points
          ]),
        stepsCount: stepsCount,
        stepsAnimation: LittleDinoAnimations.LittleDino_run_cycle,
        stepsAnimationDuration: Config.LittleDinoStepAnimationDuration,
    } as CurvePathAnimation;

    littleDinoCurvePathAnimation = {
        object: littleDinoObj,
        duration: 500,
        curve: new THREE.CatmullRomCurve3([
            new THREE.Vector3( littleDinoObj.position.x, littleDinoObj.position.y, littleDinoObj.position.z ),
            new THREE.Vector3( littleDinoObj.position.x, 0, littleDinoObj.position.z + 0.1 )
          ]),
        onFinish: () => { littleDinoCurvePathAnimation = afterReachGroundCurvePathAnimation },
        stepsCount: 1,
        stepsAnimation: LittleDinoAnimations.LittleDino_run_cycle,
        stepsAnimationDuration: 500,
        ignoreYChangesForRotation: true,
        slerpRotation: 0.15
    };
}

function LittleDinoGrabbed() {
    DropAnimationChain("littleDino");
    PlayAnimation(LittleDinoAnimations.LittleDino_grabbed, { repetitions: Number.POSITIVE_INFINITY });
}

function ExtractActionsForGreen() {
    for (let greenItem of greensScene.children) {
        if (!greenItem.userData.withArmature) continue;

        const mixer = new THREE.AnimationMixer(greenItem);
        for (let animation of green.animations) {
            const action = mixer.clipAction(animation);
            actions.push(action);
        }
        mixers.push(mixer);
    }
}

function HandleActionChainAnimationFinished(action: THREE.AnimationAction) {
    if (dinoActionsChain && action.getClip().name === dinoActionsChain.animationName) {
        StopAnimation(dinoActionsChain.animationName);
        dinoActionsChain =  dinoActionsChain.nextChain;
        if (dinoActionsChain)
            PlayAnimation(dinoActionsChain.animationName, dinoActionsChain.params);
    }
    if (littleDinoActionsChain && action.getClip().name === littleDinoActionsChain.animationName) {
        StopAnimation(littleDinoActionsChain.animationName);
        littleDinoActionsChain =  littleDinoActionsChain.nextChain;
        if (littleDinoActionsChain)
            PlayAnimation(littleDinoActionsChain.animationName, littleDinoActionsChain.params);
    }
}

function AnimationFinishedEventHandler(e: THREE.Event) {
    const action = e.action as THREE.AnimationAction;

    HandleActionChainAnimationFinished(action);
}

function ExtractActionsFromModels(models: GLTF[]) {
    for (let model of models) {
        if (model.animations?.length) {
            const mixer = new THREE.AnimationMixer(model.scene);
            mixers.push(mixer);

            for (let animation of model.animations) {
                const action = mixer.clipAction(animation);
                actions.push(action);
            }

            if (!mixer.hasEventListener("finished", AnimationFinishedEventHandler))
                mixer.addEventListener("finished", AnimationFinishedEventHandler);
        }
    }
    
    let availableAnimations = "";
    for (let action of actions)
        availableAnimations += action.getClip().name + ', ';
    console.log('Available animations: [' + availableAnimations + ']');
}

function CreateGreen() : THREE.Group {
    const scene = new THREE.Group();
    
    SetupScene(green, THREE.FrontSide, true);
    SetupScene(greenWithoutArmature, THREE.FrontSide, true);

    for (let greenTransform of PalmTransforms) {
        var currentGreen = greenTransform.useArmature ? green : greenWithoutArmature;

        const greenClone = greenTransform.useArmature ? SkeletonUtils.clone(currentGreen.scene) : currentGreen.scene.clone();
        greenClone.userData.withArmature = greenTransform.useArmature ?? false;

        greenClone.position.set(greenTransform.position.x, greenTransform.position.y, greenTransform.position.z);
        greenClone.setRotationFromEuler(greenTransform.rotation);
        scene.add(greenClone);
    }
    return scene;
}

function playRustleAnimations() {
    const timeNow = Date.now();
    if (timeNow < nextPalmRustleAnimationStartTime) return;

    for (let currAction of palmRustleActions) {
        PlayAnimationInternal(currAction, {
            clipDuration: Config.PalmRustleAnimationDurationMin + Math.random() * (Config.PalmRustleAnimationDurationMax - Config.PalmRustleAnimationDurationMin),
            actionWeight: Config.PalmRustleAnimationWeight,
            delay: Math.abs(currAction.getRoot().position.x - palmRustleActions[0].getRoot().position.x) * Config.PalmRustleAnimationDelayMultiplier,
            clampWhenFinished: true,
        });
    }

    nextPalmRustleAnimationStartTime = timeNow + Config.PalmRustleLoopDurationMin + Math.random() * (Config.PalmRustleLoopDurationMax - Config.PalmRustleLoopDurationMin);
}

function updateCurvePathAnimation(timeNow: number, animation: CurvePathAnimation | null) {
    if (!animation || animation?.finished)
        return;

    if (!animation.startTime) {
        animation.startTime = timeNow + (animation.delay ?? 0);
    }
    if (animation.startTime > timeNow) return;

    const object = animation.object;

    if (!animation.started) {
        const objectAnimations = actions.filter(x => x.getRoot().id === object.id);
        for (let objectAnimation of objectAnimations)
            objectAnimation.stop();

        PlayAnimation(animation.stepsAnimation, { repetitions: animation.stepsCount, clipDuration: animation.stepsAnimationDuration });

        animation.started = true;
    }

    const oldPosition = new THREE.Vector3();
    oldPosition.copy(object.position);
    let currentProgress = (timeNow - animation.startTime) / animation.duration;
    if (currentProgress > 1)
        currentProgress = 1;

    animation.curve.getPointAt(currentProgress, object.position);

    if (animation.ignoreYChangesForRotation)
        oldPosition.y = object.position.y;
    
    if (currentProgress > 0.001) {
        object.matrix.lookAt(object.position, oldPosition, new THREE.Vector3(0, 1, 0));
        const targetQuaternion = new THREE.Quaternion();
        targetQuaternion.setFromRotationMatrix(object.matrix);
        object.quaternion.slerpQuaternions(object.quaternion, targetQuaternion, animation.slerpRotation ?? 0.2);
    }

    // trees bend when dino walk aside
    if (animation.treesShakingDistance) {
        for (let green of greensScene.children) {
            const shakeActions = actions.filter(x => x.getRoot().id === green.id && x.getClip().name.slice(-12, -4) === "ShakingL");
            if (!shakeActions) continue;
            const greenWithArmature = GetGreenObjectsWithArmature(green);
            for (let element of greenWithArmature) {
                if (GetDistanceBetweenTwoObjects(object, element) < (animation.treesShakingDistance ?? 0)) {
                    const shakeAction = shakeActions.find(x => !x.isRunning() && x.getClip().name.slice(0, -12) === element.name.slice(0, -11) && x.getClip().name.slice(-3) === element.name.slice(-3));
                    if (shakeAction)
                        if (shakeAction.getClip().name.startsWith("Palm"))
                            PlayAnimationInternal(shakeAction, { clipDuration: Config.ShakePalmAnimationDuration, actionWeight: Config.ShakePalmAnimationWeight, clampWhenFinished: true })
                        else if (shakeAction.getClip().name.startsWith("Fern"))
                            PlayAnimationInternal(shakeAction, { clipDuration: Config.ShakeFernAnimationDuration, actionWeight: 0.8, clampWhenFinished: true })
                        else
                            PlayAnimationInternal(shakeAction, { clipDuration: Config.ShakeGreenAnimationDuration, actionWeight: Config.ShakeGreenAnimationWeight, clampWhenFinished: true })
                }
            }
        }
    }

    if (currentProgress === 1) {
        animation.finished = true;
        StopSoundByActionName(animation.stepsAnimation);
        StopAnimation(animation.stepsAnimation);
        animation.onFinish?.();
    }
}

function GetGreenObjectsWithArmature(object: THREE.Object3D): THREE.Object3D[] {
    const result: THREE.Object3D[] = [];
    object.traverse((child) => {
        if (child.name.slice(-11, -3) === "Armature")
            result.push(child);
    })
    return result;
}

export function PlayAnimation(animationName: AnimationNames, params: Partial<IPlayAnimationParams>) {
    const action = actions.find(s => s.getClip().name === animationName);
    if (action == null) {
        console.warn('Animation "' + animationName + '" not found');
        return ;
    }

    PlayAnimationInternal(action, params);
}

export function PlayAnimationsChain(type: "dino" | "littleDino", animations: { animationName: AnimationNames, params: Partial<IPlayAnimationParams>}[], loop: boolean = false) {
    let currentChain: ChainedAnimation | undefined;
    for (let animation of animations) {
        const newActionChain: ChainedAnimation = {
            animationName: animation.animationName,
            params: animation.params,
        }

        if (!currentChain) {
            currentChain = newActionChain;
            if (type === "dino")
                dinoActionsChain = currentChain;
            else if (type === 'littleDino') {
                littleDinoActionsChain = currentChain;
            }
        } else {
            currentChain.nextChain = newActionChain;
            currentChain = newActionChain;
        }
    }

    const chainByType = type === "dino" ? dinoActionsChain : type === "littleDino" ? littleDinoActionsChain : null;
    if (!chainByType) return;

    if (loop && currentChain) {
        currentChain.nextChain = chainByType;
    }

    if (chainByType)
        PlayAnimation(chainByType.animationName, chainByType.params);
}

export function DropAnimationChain(type: "dino" | "littleDino") {
    if (type === 'dino' && dinoActionsChain) {
        StopAnimation(dinoActionsChain.animationName);
        dinoActionsChain = undefined;
    } else if (type === 'littleDino' && littleDinoActionsChain) {
        StopAnimation(littleDinoActionsChain.animationName);
        littleDinoActionsChain = undefined;
    }
}

export function StopAnimation(animationName: AnimationNames) {
    const action = actions.find(s => s.getClip().name === animationName);
    if (action == null) {
        console.warn('Animation "' + animationName + '" not found');
        return ;
    }

    action.stop();
}

function PlayAnimationInternal(action: THREE.AnimationAction, params: Partial<IPlayAnimationParams>) {
    if (!action) return; 
    const locParams: IPlayAnimationParams = {
        repetitions: 1,
        clipDuration: null,
        clampWhenFinished: false,
        showLogs: false,
        actionWeight: 1,
        delay: 0,
        ...params
    }
    const mixer = action.getMixer();

    action.reset();
    action.repetitions = locParams.repetitions;
    action.clampWhenFinished = locParams.clampWhenFinished;
    action.weight = locParams.actionWeight;
    if (locParams.clipDuration != null)
        action.setDuration(locParams.clipDuration / 1000);
    const delay = (params.delay ?? 0) / 1000;
    action.startAt(mixer.time + delay);

    action.play();

    PlaySoundByAction(action);

    if (locParams.showLogs)
        console.log('Play animation "' + action.getClip().name + '" ' + locParams.repetitions + ' times')
}

export function PlaySoundByActionName(animationName: AnimationNames) {
    const action = actions.find(s => s.getClip().name === animationName);
    if (action == null) {
        console.warn('Animation "' + animationName + '" not found');
        return ;
    }

    PlaySoundByAction(action);
}

export function StopSoundByActionName(animationName: AnimationNames) {
    const action = actions.find(s => s.getClip().name === animationName);
    if (action == null) {
        console.warn('Animation "' + animationName + '" not found');
        return ;
    }

    StopSoundByAction(action);
}

function PlaySoundByAction(action: THREE.AnimationAction) {
    SoundControlByAction(action, "play");
}

function StopSoundByAction(action: THREE.AnimationAction) {
    SoundControlByAction(action, "stop");
}

function SoundControlByAction(action: THREE.AnimationAction, type: "stop" | "play") {
    let sound: THREE.PositionalAudio | undefined;
    let volume: number = 1;
    let duration: number | null = null;
    let delay = 0;

    switch (action.getClip().name) {
        case DinoAnimations.Roar: {
            sound = roarSound;
            volume = 1;
            duration = action.getClip().duration;
            break;
        }
        case DinoAnimations.Walk: {
            sound = walkSound;
            volume = 3;
            delay = 0.5;
            break;
        }
    }

    if (!sound) return;

    if (type === "play")
        PlaySound(sound, duration, volume, delay);
    else if (type === "stop") {
        sound.stop();
    }
}

function PlaySound(sound: THREE.PositionalAudio, duration: number | null = null, volume: number = 1, delay: number = 0, loop: boolean = false) {
    sound.stop();
    sound.setVolume(volume);
    sound.setLoop(loop);
    
    if (duration != null)
        sound.duration = duration;

    sound.play(delay);
}

export function PlayDinoCurvePathAnimation(dinoStepsCount: number, curve: THREE.CatmullRomCurve3, onFinish?: Function, delay: number = 0, startAfterAnimationChain: boolean = false) {
    if (startAfterAnimationChain) {
        const currentActionInChain = actions.find(x => x.getClip().name === dinoActionsChain?.animationName);
        const nextActionInChain = actions.find(x => x.getClip().name === dinoActionsChain?.nextChain?.animationName);
        if (currentActionInChain) {
            delay += currentActionInChain.getClip().duration * 1000 - currentActionInChain.time * 1000;
        }
        if (nextActionInChain) {
            delay += nextActionInChain.getClip().duration * 1000;
        }
        if (dinoActionsChain?.nextChain?.nextChain)
            dinoActionsChain.nextChain.nextChain = undefined;
    }

    dinoCurvePathAnimation = {
        object: dino.scene,
        duration: dinoStepsCount * Config.DinoWalkAnimationDuration,
        treesShakingDistance: Config.TreesShakingDistanceWhenDinoMovesNear,
        curve: curve,
        onFinish: onFinish,
        delay: delay,
        stepsCount: dinoStepsCount,
        stepsAnimation: DinoAnimations.Walk,
        stepsAnimationDuration: Config.DinoWalkAnimationDuration,
    };
}

export default GLTFModel;