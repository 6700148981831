import { useFrame } from "@react-three/fiber"
import { useXR } from "@react-three/xr"
import { useEffect } from "react"
import * as THREE from "three"

interface IUserProps {
  initPosition?: THREE.Vector3,
  onGamepadButtonPressed?: (buttonIndex: number) => any,
  onPlayerTransformChanged?: (position: THREE.Vector3, rotation: THREE.Quaternion) => any
}

let xrReferenceSpace: XRReferenceSpace | null = null;

function GetReferenceSpace(session: XRSession | null) {
  if (session) {
    session.requestReferenceSpace('local-floor')
    .then((refSpace) => {
      const viewerStartPosition = new THREE.Vector3(0, 0, 0);
      const viewerStartOrientation = new THREE.Vector3(0, 0, 0);

      xrReferenceSpace = refSpace.getOffsetReferenceSpace(new XRRigidTransform(viewerStartPosition, viewerStartOrientation));
    });
  }
}

export default function Player(props: IUserProps) {
  const { player, session } = useXR();

  useFrame(() => {
    if (session) {
      // Handle player position
      if (!xrReferenceSpace)
        GetReferenceSpace(session);

      if (xrReferenceSpace) {
        session.requestAnimationFrame((time, frame) => {
          const pose = frame.getViewerPose(xrReferenceSpace as XRReferenceSpace);
          if (pose) {
            const localPos = pose.transform.position;
            props.onPlayerTransformChanged?.(new THREE.Vector3(player.position.x + localPos.x, player.position.y + localPos.y, player.position.z + localPos.z), new THREE.Quaternion(pose.transform.orientation.x, pose.transform.orientation.y, pose.transform.orientation.z, pose.transform.orientation.w));
          }
        });
      }

      // Handle button pressed
      for (let i = 0; i < session.inputSources?.length; i++) {
        const buttons = session.inputSources[i].gamepad?.buttons;
        if (!buttons) continue;
        
        for (let j = 0; j < buttons.length; j++) {
          if (buttons[j].value === 1) {
            props.onGamepadButtonPressed?.(j);
          }
        }
      }
    }
  })

  useEffect(() => {
    if (props.initPosition) {
      player.position.x = props.initPosition.x;
      player.position.y = props.initPosition.y;
      player.position.z = props.initPosition.z;
      player.lookAt(new THREE.Vector3(props.initPosition.x,props.initPosition.y,0));
      player.rotateY(Math.PI)
    }

    GetReferenceSpace(session);
  }, [player, session, props.initPosition]);

  return (
    <>
    </>
  )
}