import { XR, Controllers, Hands, VRButton } from '@react-three/xr'
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { useState } from 'react'
import './App.css'
import { Scene } from './Scene';
import FogEffect from './FogEffect';
import ModelsLoadingIndicator from './ModelsLoadingIndicator'

export default function App() {
  const [XREnabled, setXREnabled] = useState(false)
  const [loadFinished, setLoadFinished] = useState(false)

  return (
    <>
      <Canvas style={{width: '100%', height: '100%'}} dpr={[1,2]}>
        <XR onSessionStart={() => {setXREnabled(true)}} onSessionEnd={() => {setXREnabled(false)}}>
          <ambientLight intensity={0.7} />
          <Controllers />
          <Hands />
          <FogEffect />
          <Scene scale={0.7} xrEnabled={XREnabled} onInit={() => setLoadFinished(true)} />
          {!XREnabled ? <OrbitControls target={[0,0,0]} /> : null}
        </XR>
      </Canvas>
      {!loadFinished ? <ModelsLoadingIndicator /> : null}
      {!XREnabled && loadFinished ? <VRButton/> : <></>}
    </>
  )
}
