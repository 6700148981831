export const DinoAnimations = {
    Roar: "Roar" as const,
    Walk: "Walk" as const,
    Smell: "Smell" as const
};

export const LittleDinoAnimations = {
    LittleDino_idle001: "LittleDino_idle.001" as const,
    LittleDino_idle: "LittleDino_idle" as const,
    LittleDino_grabbed: "LittleDino_grabbed" as const,
    LittleDino_run_cycle: "Armature.001|Armature|run_cycle" as const,
};

export type AnimationNames = 
    | typeof DinoAnimations[keyof typeof DinoAnimations]
    | typeof LittleDinoAnimations[keyof typeof LittleDinoAnimations];

export type ChainedAnimation = {
    animationName: AnimationNames,
    params: Partial<IPlayAnimationParams>,
    nextChain?: ChainedAnimation
}

export interface IPlayAnimationParams {
    repetitions: number,
    clipDuration: number | null,
    clampWhenFinished: boolean,
    showLogs: boolean,
    actionWeight: number,
    delay: number,
}