import React, { Suspense, useEffect, useReducer, useRef, useState } from 'react';
import { Vector3, addAfterEffect, addEffect, useFrame, useThree } from '@react-three/fiber'
import GLTFModel, { LittleDinoDrop, PlayAnimation, PlayAnimationsChain, PlayDinoCurvePathAnimation, StopAnimation, littleDino } from './GLTFModel';
import { Environment, Html, Plane, Text } from '@react-three/drei';
import * as THREE from "three";
import River2 from './River2';
import Player from './Player';
import { DinoAnimations, LittleDinoAnimations } from './definitions/Animations';
import Curve from './components/Curve';
import Stats from "stats-gl";

export interface ISceneProps {
  scale?: number,
  xrEnabled?: boolean,
  onInit?: Function
}

const Config = {
  MoveToRestSpotStepsCount: 8,
  MoveInFrontOfJungleStepsCount: 2,
  MoveToUserStepsCount: 2,
  RoarClipDuration: 4000,
  RestartButtonIndex: 5,
  DistanceToLittleDinoToTriggerDinosaur: 2,
  DelayBeforeDinoMovesAway: 4000,
  DinoMovesAwayStepsCount: 6,

  PlayerInitPosition: new THREE.Vector3(-4.2, 0.16, 44.5),
  LittleDinoInitPosition: new THREE.Vector3(-6, 0.16, 59),
    

  ShowDebuggingInfo: false, // Do not affect build version even if set true
}

let dinosaurTriggered = false;
let dinosaurAtTheRestSpot = false;
let dinosaurIsNearPlayer = false;
let dinosaurWantsToWalkAway = false;

const dropLittleDinoSpot = new THREE.Vector3(Config.PlayerInitPosition.x / 0.7, Config.PlayerInitPosition.y / 0.7, Config.PlayerInitPosition.z / 0.7);

let refStats: React.RefObject<THREE.Group>;
const hudWorldScale = new THREE.Vector3();

//#region Debug components
function HTMLTools(props: { forceUpdate?: Function }) {
  return (
    <Html transform occlude position={[0,150, -200]}>
        <button style={{width: '6000px', height: '1000px', fontSize: '500px', marginTop: '100px'}} onClick={() => DinosaurRunsToUserAnimation()}>
          Start dino run to user
        </button>
        <button style={{width: '6000px', height: '1000px', fontSize: '500px', marginTop: '100px'}} onClick={() => { if (littleDino.scene.parent) littleDino.scene.parent.position.set(dropLittleDinoSpot.x - Config.LittleDinoInitPosition.x, 2, dropLittleDinoSpot.z - Config.LittleDinoInitPosition.z); littleDino.scene.parent?.rotateY(Math.PI); LittleDinoDrop(); DinosaurWalksAwayAnimation(); }}>
          LittleDino drop animation
        </button>
        <button style={{width: '6000px', height: '1000px', fontSize: '500px', marginTop: '100px', scale: 2}} onClick={() => props.forceUpdate?.()}>
          Restart scene
        </button>
    </Html>
  )
}

const stats = new Stats();
function ShowVRStats() {
  const ref = useRef(null);
  refStats = useRef<THREE.Group>(null);
  let [fps, setFps] = useState("");
  const hudHeight = 0.35;
  const hudWidth = 0.4;
  const fontSize = 0.1;
  const rowSpacing = 0.05;

  useEffect(() => {
    document.body.appendChild(stats.container)

    let prevTime = 0;
    let frames = 0;

    const begin = addEffect(() => {
      stats.begin();
    });
    const end = addAfterEffect(() => {
      prevTime = stats.prevTime;
      frames = stats.frames;
      stats.end();
      if ( stats.prevTime >= prevTime + 1000 ) {
        const fps = ( frames * 1000 ) / ( stats.prevTime - prevTime );
        setFps(fps.toFixed(0));
      }
    });

    return () => {
      document.body.removeChild(stats.container);
      begin();
      end();
    }
  }, []);

  return (
    <group ref={refStats} visible={false}>
      <group position={[0.45,0,-1]}>
        <Plane args={[hudWidth, hudHeight, 1, 1]}>
          <meshBasicMaterial polygonOffset={true} polygonOffsetUnits={-100000000} color={new THREE.Color(0x000040)} />
        </Plane>
        <Text position={[0,hudHeight / 2 - fontSize,0]} depthOffset={-100000000} fontSize={fontSize}>HUD</Text>
        <Text position={[0,hudHeight / 2 - fontSize - rowSpacing - fontSize,0]} depthOffset={-100000000} fontSize={fontSize} ref={ref} color={new THREE.Color("cyan")}>FPS: {fps}</Text>
      </group>
    </group>
  )
}

function DebugComponents(props: { forceUpdate?: Function, showCurves: boolean }) {
  return (
    <>
      <HTMLTools forceUpdate={props.forceUpdate} />
      {props.showCurves ? <>
        <Curve curve={moveToRestSpotSpline} />
        <Curve curve={moveOutOfJungleSpline} />
        <Curve curve={moveToPlayerSpline} />
        <Curve curve={dinoMovesAwayAfterLittleDinoDropCurve} />
      </> : <></>}

      <ShowVRStats />
    </>
  )
}
//#endregion

function Light(props: {intensity: number, position: Vector3}) {
  return (
    <group>
      <spotLight intensity={props.intensity} angle={90} penumbra={1} position={props.position} castShadow />
    </group>
  )
}

const moveToRestSpotSpline = new THREE.CatmullRomCurve3( [
  new THREE.Vector3( -15, 0, -65 ),
  new THREE.Vector3( -21, 0, -20 ),
  new THREE.Vector3( -15, 0, 0 ),
  new THREE.Vector3( -11, 0, 10.5 ),
] );

const moveOutOfJungleSpline = new THREE.CatmullRomCurve3( [
  new THREE.Vector3( -11, 0, 10.5 ),
  new THREE.Vector3( -10.5, 0, 13 ),
  new THREE.Vector3( -9.5, 0, 27 ),
  new THREE.Vector3( -9.3, 0, 29 ),
] );

const moveToPlayerSpline = new THREE.CatmullRomCurve3( [
  new THREE.Vector3( -9.3, 0, 29 ),
  new THREE.Vector3( -8.9, 0, 32 ),
  new THREE.Vector3( -7.5, 0, 43 ),
] );

const dinoMovesAwayAfterLittleDinoDropCurve = new THREE.CatmullRomCurve3([
    new THREE.Vector3( -7.5, 0, 43 ),
    new THREE.Vector3( -6.6, 0, 46 ),
    new THREE.Vector3( -4, 0, 49 ),
    new THREE.Vector3( 0, 0, 50 ),
    new THREE.Vector3( 3, 0, 49.5 ),
    new THREE.Vector3( 5.5, 0, 48 ),
    new THREE.Vector3( 7.8, 0, 44 ),
    new THREE.Vector3( 9.3, 0, 38 ),
    new THREE.Vector3( 10, 0, 33 ),
    new THREE.Vector3( 11, 0, 25 ),
    new THREE.Vector3( 11, 0, 10 ),
]);

function StartSceneAnimations() {
  PlayAnimationsChain('littleDino', [
    { animationName: LittleDinoAnimations.LittleDino_idle001, params: {} },
    { animationName: LittleDinoAnimations.LittleDino_idle, params: {} },
  ], true);

  PlayDinoCurvePathAnimation(Config.MoveToRestSpotStepsCount, moveToRestSpotSpline, () => {
    if (dinosaurTriggered && !dinosaurAtTheRestSpot) {
      DinosaurRunsToUserAnimation();
    }
    dinosaurAtTheRestSpot = true;

    PlayAnimation(DinoAnimations.Smell, { repetitions: Number.POSITIVE_INFINITY });
  });
}

function DinosaurRunsToUserAnimation() {
  StopAnimation(DinoAnimations.Smell);
  PlayDinoCurvePathAnimation(Config.MoveInFrontOfJungleStepsCount, moveOutOfJungleSpline, () => {
    PlayAnimation(DinoAnimations.Roar, { repetitions: 1, clipDuration: Config.RoarClipDuration });

    PlayDinoCurvePathAnimation(Config.MoveToUserStepsCount, moveToPlayerSpline, () => {
      PlayAnimationsChain('dino', [
        { animationName: DinoAnimations.Roar, params: { clipDuration: Config.RoarClipDuration } },
        { animationName: DinoAnimations.Smell, params: {} }
      ], true);
      dinosaurIsNearPlayer = true;
      if (dinosaurWantsToWalkAway)
        DinosaurWalksAwayAnimation();
    }, Config.RoarClipDuration);
  });
}

function DinosaurWalksAwayAnimation() {
  PlayDinoCurvePathAnimation(Config.DinoMovesAwayStepsCount, dinoMovesAwayAfterLittleDinoDropCurve, () => {
    PlayAnimation(DinoAnimations.Smell, { repetitions: Number.POSITIVE_INFINITY });
  }, 0, true);
}

function LittleDinoDropped() {
  if (dinosaurIsNearPlayer)
    DinosaurWalksAwayAnimation();
  else
    dinosaurWantsToWalkAway = true;
}

function GamepadButtonPressed(buttonIndex: number, func: Function) {
  if (buttonIndex === Config.RestartButtonIndex)
    func();
}

function PlayerTransformChanged(position: THREE.Vector3, rotation: THREE.Quaternion) {
  CheckIfPlayerNearLittleDino(position);

  if (refStats.current) {
    if (!refStats.current.visible)
      refStats.current.visible = true;
    refStats.current.getWorldScale(hudWorldScale);
    refStats.current.position.set(position.x / hudWorldScale.x, position.y / hudWorldScale.y, position.z / hudWorldScale.z);
    refStats.current.setRotationFromQuaternion(rotation);
  }
}

function CheckIfPlayerNearLittleDino(position: THREE.Vector3) {
  if (dinosaurTriggered || !littleDino || !littleDino.scene) return;

  const distanceVector = new THREE.Vector3();
  distanceVector.subVectors(position, littleDino.scene.getWorldPosition(new THREE.Vector3()));
  if (distanceVector.length() < Config.DistanceToLittleDinoToTriggerDinosaur) {
    if (dinosaurAtTheRestSpot && !dinosaurTriggered)
      DinosaurRunsToUserAnimation();
    dinosaurTriggered = true;
  }
}

function InitScene() {
  StartSceneAnimations();
}

export function Scene (props: ISceneProps) {
  const { camera } = useThree();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const [listener] = useState(() => new THREE.AudioListener());

  dinosaurTriggered = false;
  dinosaurAtTheRestSpot = false;
  dinosaurIsNearPlayer = false;
  dinosaurWantsToWalkAway = false;

  useEffect(() => {
    if (!camera.children.find(x => x.id === listener.id))
      camera.add(listener);
  }, [camera, listener]);

  return (
    <group scale={props.scale ?? 1}>
      { process.env.NODE_ENV === "development" && Config.ShowDebuggingInfo ? <DebugComponents forceUpdate={forceUpdate} showCurves={false} /> : null }
      <Light position={[0,100,-100]} intensity={0.2} />
      <Environment files='morning.hdr' background='only' blur={0}  />
      <River2 />
      {props.xrEnabled ? <Player initPosition={Config.PlayerInitPosition} onGamepadButtonPressed={(ind) => GamepadButtonPressed(ind, forceUpdate)} onPlayerTransformChanged={PlayerTransformChanged} /> : null}
      <Suspense fallback={null}>
        <GLTFModel listener={listener} dropLittleDinoSpot={dropLittleDinoSpot} littleDinoInitPosition={Config.LittleDinoInitPosition} onInit={() => { props.onInit?.(); InitScene(); } } onDropLittleDino={LittleDinoDropped} />
      </Suspense>
    </group>
  );
}
