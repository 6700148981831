import * as THREE from 'three'

export const PalmTransforms: { position: THREE.Vector3, rotation: THREE.Euler, useArmature?: boolean }[] = [
    {
        position: new THREE.Vector3(35.688514709472656, 0, 18.0),
        rotation: new THREE.Euler(0, 2.5693270683288574, 0),
        useArmature: true,
    },
    {
        position: new THREE.Vector3(-18.34200096130371, 0, -7.0),
        rotation: new THREE.Euler(0, 0.059196051210165024, 0),
        useArmature: true,
    },
    {
        position: new THREE.Vector3(-42.30071258544922, 0, -20.740142822265625),
        rotation: new THREE.Euler(0, 0.0, 0),
    },
    {
        position: new THREE.Vector3(10.688514709472656, 0, -15.296285629272461),
        rotation: new THREE.Euler(0, 0.0, 0),
    },
    {
        position: new THREE.Vector3(-50.65800094604492, 0, 32.986427307128906),
        rotation: new THREE.Euler(0, 0.9166179299354553, 0),
        useArmature: true,
    },
    {
        position: new THREE.Vector3(-55.67390441894531, 0, 0.262844085693359),
        rotation: new THREE.Euler(0, 1.015302300453186, 0),
    },
    {
        position: new THREE.Vector3(-10.85848617553711, 0, -34.84785842895508),
        rotation: new THREE.Euler(0, 0.0, 0),
        useArmature: true,
    },
    {
        position: new THREE.Vector3(-10.85848617553711, 0, -62.03542709350586),
        rotation: new THREE.Euler(0, 0.0, 0),
        useArmature: true,
    },
    {
        position: new THREE.Vector3(25.196828842163086, 0, -85.78785705566406),
        rotation: new THREE.Euler(0, 0.0, 0),
    },
    {
        position: new THREE.Vector3(-81.67390441894531, 0, 4.262844085693359),
        rotation: new THREE.Euler(0, 1.015302300453186, 0),
    },
    {
        position: new THREE.Vector3(-53.58238983154297, 0, -41.833248138427734),
        rotation: new THREE.Euler(0, 0.4497469961643219, 0),
    },
    {
        position: new THREE.Vector3(-93.00485229492188, 0, -26.9522705078125),
        rotation: new THREE.Euler(0, 0.4497469961643219, 0),
    },
    {
        position: new THREE.Vector3(-115.90338134765625, 0, 15.943817138671875),
        rotation: new THREE.Euler(0, 0.8782227635383606, 0),
    },
    {
        position: new THREE.Vector3(-67.30126190185547, 0, -76.6579360961914),
        rotation: new THREE.Euler(0, 0.16118375957012177, 0),
    },
    // {
    //     position: new THREE.Vector3(-14.543105125427246, 0, -115.57901000976562),
    //     rotation: new THREE.Euler(0, 0.0, 0),
    // },
    // {
    //     position: new THREE.Vector3(50.02134323120117, 0, -122.93147277832031),
    //     rotation: new THREE.Euler(0, 0.0, 0),
    // },
    // {
    //     position: new THREE.Vector3(-60.06155776977539, 0, -141.85269165039062),
    //     rotation: new THREE.Euler(0, 0.0, 0),
    // },
    {
        position: new THREE.Vector3(1.088514709472656, 0, 22.0),
        rotation: new THREE.Euler(0, -0.1993270683288574, 0),
        useArmature: true,
    },
    {
        position: new THREE.Vector3(-22.688514709472656, 0, 6.0),
        rotation: new THREE.Euler(0, 0.1993270683288574, 0),
        useArmature: true,
    },
    // no dino canyon side - front
    {
        position: new THREE.Vector3(-72.86182403564453, 0, 89.00004577636719),
        rotation: new THREE.Euler(0, -0.6023762226104736, 0),
        useArmature: true,
    },
    {
        position: new THREE.Vector3(-39.563716888427734, 0, 96.03773498535156),
        rotation: new THREE.Euler(0, 0.0, 0),
        useArmature: true,
    },
    {
        position: new THREE.Vector3(8.196826934814453, 0, 96.03773498535156),
        rotation: new THREE.Euler(0, 0.0, 0),
        useArmature: true,
    },
    {
        position: new THREE.Vector3(45.027626037597656, 0, 92.755615234375),
        rotation: new THREE.Euler(0, 0.8398347496986389, 0),
        useArmature: true,
    },
    {
        position: new THREE.Vector3(85.83981323242188, 0, 88.55439758300781),
        rotation: new THREE.Euler(0, 0.0, 0),
    },
    // no dino canyon side - back
    {
        position: new THREE.Vector3(-80.79190826416016, 0, 107.3978271484375),
        rotation: new THREE.Euler(0, -0.6023762226104736, 0),
    },
    {
        position: new THREE.Vector3(49.027626037597656, 0, 110.67759704589844),
        rotation: new THREE.Euler(0, 0.8398347496986389, 0),
    },
    {
        position: new THREE.Vector3(-39.563716888427734, 0, 118.959716796875),
        rotation: new THREE.Euler(0, -0.2, 0),
    },
    {
        position: new THREE.Vector3(0.430755615234375, 0, 120.59412384033203),
        rotation: new THREE.Euler(0, 0.4, 0),
    },
]