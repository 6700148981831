import { useFrame, useLoader } from '@react-three/fiber';
import * as THREE from "three";

function River2() {
    const texture = useLoader(
        THREE.TextureLoader, "/water.jpg"
      );
    const normalTexture = useLoader(
        THREE.TextureLoader, "/water_normal.jpg"
    );
    
    // assuming you want the texture to repeat in both directions:
    texture.wrapS = THREE.RepeatWrapping; 
    texture.wrapT = THREE.RepeatWrapping;

    normalTexture.wrapS = THREE.RepeatWrapping; 
    normalTexture.wrapT = THREE.RepeatWrapping;
    
    // how many times to repeat in each direction; the default is (1,1),
    //   which is probably why your example wasn't working
    texture.repeat.set( 36, 4 ); 
    normalTexture.repeat.set( 36, 4 ); 
    
    const material = new THREE.MeshPhongMaterial({ color: 0x00335DBB, map: texture, normalMap: normalTexture, bumpMap: normalTexture, displacementMap: normalTexture, displacementScale: 80, normalScale: new THREE.Vector2(5,5) });
    const plane = new THREE.PlaneGeometry(880, 80, 80, 10);

    useFrame(() => {
        const offset = new THREE.Vector2(texture.offset.x, texture.offset.y);
        offset.x += 0.015;
        // offset.y += Math.random() * 0.006 - 0.003
        if (offset.x > 1) {
            offset.x -= 1;
        }
        if (offset.y > 1) {
            offset.y -= 1;
        }
        if (offset.y < 0) {
            offset.y += 1;
        }
        texture.offset = offset;
        // normalTexture.offset = offset;
    })

    return (
        <mesh geometry={plane} material={material} rotation-x={-Math.PI / 2} position={[0, -150, 60]} />
    )
}

export default River2;