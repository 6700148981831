import { Intersection, useFrame } from "@react-three/fiber";
import { XRController, useController, useXR } from "@react-three/xr";
import { useEffect, useRef, useState } from "react"
import * as THREE from "three";
import { GetDistanceBetweenTwoObjects, GetDistanceBetweenTwoVectors } from "./utils/utils";
import { Float, FloatProps, Sphere } from "@react-three/drei";

interface IGrabProps {
    targetName?: string,
    onGrab?: Function,
    onDrop?: Function,
    position?: THREE.Vector3,
    dropSpot?: THREE.Vector3,
}

export let targetObj: THREE.Object3D | null = null;
let objectIsGrabbed = false;
let objectIsDropped = false;
let grabDistanceBetweenHands = 0;
const CollisionDistance = 0.08;
const DropExtraDistanceBetweenHands = 0.15;
const DistanceToDropSpot = 0.3;
const DropSpotPointLightIntensity = 7;
const timeBetweenCollisionChecks = 100; // ms

//#region For debug
let attachedL = false;
let attachedR = false;
function addSphere(target: THREE.Object3D | null | undefined, color: THREE.Color) {
    if (!target) return false

    const geometry = new THREE.SphereGeometry( 0.01, 32, 16 ); 
    const material = new THREE.MeshBasicMaterial( { color: color } ); 
    const sphere = new THREE.Mesh( geometry, material );
    let targetPos = new THREE.Vector3();
    target.getWorldPosition(targetPos)

    sphere.position.set(targetPos.x, targetPos.y, targetPos.z)
    target.attach(sphere);

    return true
}

function ShowJoints(controller?: XRController) {
    if (!controller) return false;
    return addSphere(controller.hand.joints["wrist"], new THREE.Color(0xff0000)) &&
    addSphere(controller.hand.joints["thumb-tip"], new THREE.Color(0x00ff00)) &&
    addSphere(controller.hand.joints["ring-finger-tip"], new THREE.Color(0x0000ff)) &&
    addSphere(controller.hand.joints["index-finger-tip"], new THREE.Color(0x00ffff)) &&
    addSphere(controller.hand.joints["pinky-finger-tip"], new THREE.Color(0xffff00)) &&
    addSphere(controller.hand.joints["middle-finger-tip"], new THREE.Color(0xff00ff));
}
//#endregion

const sourceWorldPos = new THREE.Vector3();
const targetWorldPos = new THREE.Vector3();
const directionVector = new THREE.Vector3();
const collisionResults: Intersection[] = [];
function CheckOnCollision(raycaster: THREE.Raycaster, collisionDistance: number, source?: THREE.Object3D, target?: THREE.Object3D): boolean {
    if (!source || !target) return false;

    source.getWorldPosition(sourceWorldPos);
    target.getWorldPosition(targetWorldPos);
    directionVector.subVectors(targetWorldPos, sourceWorldPos);

    raycaster.set(sourceWorldPos, directionVector.normalize());
    collisionResults.length = 0;
    target.raycast(raycaster, collisionResults);

    // if ((there are any collisions && first collision is close enough to collide) || source inside target)
    if ((collisionResults.length > 0 && collisionResults[0].distance < collisionDistance) || collisionResults.length % 2 === 1)
        return true;
    else
        return false;
}

export function Grab(props: IGrabProps & { children: React.ReactNode }) {
    const ref = useRef<THREE.Group>(null);
    const refParent = useRef<THREE.Group>(null);
    const refGrabPivot = useRef<THREE.Group>(null);
    const refDropSpot = useRef<THREE.Group>(null);
    const refDropSpotPointLight = useRef<THREE.PointLight>(null);

    const { isPresenting } = useXR();
    const rightController = useController("right");
    const leftController = useController("left");
    const [raycaster] = useState(() => new THREE.Raycaster());

    let lastTimeCollisionCheck = 0;

    useFrame(() => {
        if (isPresenting && targetObj && !objectIsDropped) {
            const hands = rightController?.inputSource.hand ? true : false;
            const leftGrab = hands ? leftController?.hand.joints["middle-finger-metacarpal"] : leftController?.grip;
            const rightGrab = hands ? rightController?.hand.joints["middle-finger-metacarpal"] : rightController?.grip;
            let grabObject = false;

            // Check if object grabbed (collisions check)
            if (!objectIsGrabbed) {
                const time = Date.now();
                if (time - lastTimeCollisionCheck > timeBetweenCollisionChecks) {
                    const leftCollision = CheckOnCollision(raycaster, CollisionDistance, leftGrab, targetObj);
                    
                    if (leftCollision && CheckOnCollision(raycaster, CollisionDistance, rightGrab, targetObj))
                        grabObject = true;

                    lastTimeCollisionCheck = time;
                }
            }

            if (refGrabPivot.current && refParent.current && (objectIsGrabbed || grabObject)) {
                // Update grabbed object pivot position
                if (leftGrab && rightGrab) {
                    const newPos = (new THREE.Vector3()).addVectors(rightGrab.getWorldPosition(new THREE.Vector3()), leftGrab.getWorldPosition(new THREE.Vector3()))
                                                        .multiplyScalar(0.5)                                                            // Get middle point between two fingers (controllers) in world space
                                                        .sub(refParent.current.getWorldPosition(new THREE.Vector3()))                   // Translate it to local space
                                                        .multiplyScalar(1 / refParent.current.getWorldScale(new THREE.Vector3()).x);    // Apply parent's scale to position (assumed it equals for each demecsion)
                    refGrabPivot.current.position.set(newPos.x, newPos.y, newPos.z);
                    refGrabPivot.current.lookAt(rightGrab.getWorldPosition(new THREE.Vector3()));
                }

                // Check for drop object
                if (ref.current && objectIsGrabbed && GetDistanceBetweenTwoObjects((leftGrab as THREE.Object3D), (rightGrab as THREE.Object3D)) > grabDistanceBetweenHands + DropExtraDistanceBetweenHands) {
                    const grabPivotRelativePosition = new THREE.Vector3().addVectors(refGrabPivot.current.position, refParent.current.position);
                    if (!props.dropSpot || (props.dropSpot && GetDistanceBetweenTwoVectors(props.dropSpot, grabPivotRelativePosition, "y") < DistanceToDropSpot)) {
                        refParent.current.attach(ref.current);
                        objectIsDropped = true;
                        objectIsGrabbed = false;
                        props.onDrop?.();
                        if (refDropSpot.current)
                            refDropSpot.current.visible = false;
                        if (refDropSpotPointLight.current)
                            refDropSpotPointLight.current.intensity = 0;
                    }
                }
            }

            // Grab an object if collision check succeeded
            if (grabObject && ref.current && refGrabPivot.current) {
                grabObject = false;
                refGrabPivot.current.attach(ref.current);
                grabDistanceBetweenHands = GetDistanceBetweenTwoObjects((leftGrab as THREE.Object3D), (rightGrab as THREE.Object3D));
                objectIsGrabbed = true;
                props.onGrab?.();
                if (refDropSpot.current)
                    refDropSpot.current.visible = true;
                if (refDropSpotPointLight.current)
                    refDropSpotPointLight.current.intensity = DropSpotPointLightIntensity;
            }
        }
    });

    useEffect(() => {
        if (ref.current) {
            if (!props.targetName)
                targetObj = ref.current
            else
                ref.current.traverse((child:any) => {
                    if (child.name === props.targetName) {
                        targetObj = child;
                    }
                })
        }

        if (ref.current) {
            refParent.current?.attach(ref.current);
            ref.current.position.set(0,0,0);
            ref.current.rotation.set(0,0,0);
        }

        objectIsGrabbed = false;
        objectIsDropped = false;
        grabDistanceBetweenHands = 0;
    }, [props, props.targetName, props.position, props.children])

    useEffect(() => {
        if (process.env.NODE_ENV === "development" && false) {
            if (!attachedL)
                attachedL = ShowJoints(leftController);
            if (!attachedR)
                attachedR = ShowJoints(rightController);
        }
    }, [leftController, rightController]);

    useEffect(() => {
        if (refDropSpot.current)
            refDropSpot.current.visible = false;
    }, [refDropSpot])

    useEffect(() => {
        if (refDropSpotPointLight.current)
            refDropSpotPointLight.current.intensity = 0;
    }, [refDropSpotPointLight])

    return (
        <>
            {props.dropSpot ?
                <>
                    <pointLight ref={refDropSpotPointLight} position={props.dropSpot} color='yellow' intensity={0} distance={0.4} castShadow={false} />
                    <group ref={refDropSpot} visible={false} position={props.dropSpot}>
                    {
                        Array.from({ length: 10 }).map((it, index) =>
                            <Particle key={index} position={new THREE.Vector3(Math.random() * 0.2 - 0.1, 0, Math.random() * 0.2 - 0.1)} speed={Math.random() * 7 + 7} />
                        )
                    }
                    </group>
                </>
                : null
            }
            <group ref={refParent} position={props.position}>
                <group ref={refGrabPivot} />
                <group ref={ref}>
                    {props.children}
                </group>
            </group>
        </>
    )
}

function Particle(props: FloatProps) {
    const ref = useRef<THREE.Group>(null);
    let prevYPos = 0;
    const height = 5;
    const delta = 0.1;

    useFrame(() => {
        if (!ref.current) return;

        const newOpacity = Math.min(Math.max(height - Math.pow(ref.current.position.y * 2, 2), 0), height);
        
        if (ref.current.position.y > prevYPos)
            ((ref.current.children[0] as THREE.Mesh).material as THREE.Material).opacity = newOpacity;

        prevYPos = ref.current.position.y;
    });

    return <Float ref={ref} speed={8} floatIntensity={1} floatingRange={[-0.1, height + delta]} {...props}>
        <Sphere args={[0.02, 1, 1]}>
            <meshBasicMaterial color="yellow" transparent />
        </Sphere>
    </Float>
}