import * as THREE from 'three'

export function GetDistanceBetweenTwoObjects(object: THREE.Object3D, target: THREE.Object3D): number {
    if (!object || !target) return Number.POSITIVE_INFINITY;
    var objectWorldPosition = new THREE.Vector3();
    var targetWorldPosition = new THREE.Vector3();
    var distanceVector = new THREE.Vector3();

    object.getWorldPosition(objectWorldPosition);
    target.getWorldPosition(targetWorldPosition);
    distanceVector.subVectors(objectWorldPosition, targetWorldPosition);
    return distanceVector.length();
};

export function GetDistanceBetweenTwoVectors(vec1: THREE.Vector3, vec2: THREE.Vector3, excludeAxes: string = ''): number {
    if (!vec1 || !vec2) return Number.POSITIVE_INFINITY;
    const vec1Copy = new THREE.Vector3().copy(vec1);
    const vec2Copy = new THREE.Vector3().copy(vec2);
    var distanceVector = new THREE.Vector3();
    excludeAxes = excludeAxes.replace(/[^xyz]/g, ''); // Remove all characters except x, y or z
    if (excludeAxes.length > 3) return Number.POSITIVE_INFINITY;
    for (let c of excludeAxes) {
        // @ts-ignore
        vec1Copy[c] = 0;
        // @ts-ignore
        vec2Copy[c] = 0;
    }
    distanceVector.subVectors(vec1Copy, vec2Copy);
    return distanceVector.length();
};